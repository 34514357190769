/*
 * Set the background to black
 *
 */

const setBackgroundToBlack = function () {
  let main = document.getElementsByTagName('main')[0];
  main.style['background-color'] = '#000'
};

exports.setBackgroundToBlack = setBackgroundToBlack;